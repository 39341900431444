
import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';

import { GlobalesService } from './globales.service';


import { read as XLSXRead,utils as XLSXUtils, WorkSheet as  XLSXWorkSheet }  from "xlsx";
import { PuertoService } from './puerto.service';
import { UbicacionService } from './ubicacion.service';
import { BancoService } from './banco.service';
import { PolicyService } from './policy.service';
import { UsuarioService } from './usuario.service';


@Injectable({
  providedIn: 'root'
})
export class PdaService {

  pdaViewer: any;
  apiKeyMT ='91d53422a27728cda60c54bf76cb6a425190ec9f';
  urlMT ='https://services.marinetraffic.com/api';

  example:'https://services.marinetraffic.com/api/vesselmasterdata/91d53422a27728cda60c54bf76cb6a425190ec9f?v=1&imo=9376000&interval=minutes&protocol=json&page=2&msgtype=simple'

  zonas: any[]=[];
  puertos: any[]=[];
  currencys: any[]=[];
  activitys: any[]=[];
  paises:any[]=[];
  policys:any[]=[];
  banks:any[]=[];

  puertoService=inject(PuertoService);
  ubicacionService=inject(UbicacionService);
  bancoService=inject(BancoService);
  policyService=inject(PolicyService);
  usuarioService=inject(UsuarioService);

  async loadOpciones(){

   
    //if (this.puertos.length === 0){

     
      
     await new Promise((resolve, rejects) => { this.puertoService.getZonas()
      .subscribe(
        (data: any) => {
          this.zonas = data;
          console.log(this.zonas);
          resolve('');
        },
        (err) => {
          rejects('');
        }
      )})
    //};
    

    //if (this.activitys.length === 0){

      
      await new Promise((resolve, rejects) => { this.puertoService.getActivityVessel().subscribe(
        (data: any) => {
          this.activitys = data;
          resolve('');
        },
        (err) => {
          rejects('');
        }
      )})
    //};

    //if (this.paises.length === 0){

      if (sessionStorage.getItem('getPaises')) {
        this.paises = JSON.parse(sessionStorage.getItem('getPaises'));
        this.paises.unshift({'idPais':'TBN','nombrePais':'TBN','codePais':'TBN'})

      } else {
        await new Promise((resolve, rejects) => {   this.ubicacionService.getPaises().subscribe(
          (data) => {
            this.paises = data;
            sessionStorage.setItem('getPaises', JSON.stringify(data));
            this.paises.unshift({'idPais':'TBN','nombrePais':'TBN','codePais':'TBN'})
            resolve('');
          },
          (err) => {
            rejects('');
          }
        )})
      };

    //}

    //if (this.policys.length === 0){
      await new Promise((resolve, rejects) => {  this.policyService
      .getPolicys(this.usuarioService.usuario.empresa.idEmpresa, false)
      .subscribe(
        (data: any) => {
          if (data) {
          
            this.policys = data;
            resolve('');
          }
        },
        (error) => {
          rejects('');

        }
      )})
    //};
    

    //if (this.banks.length === 0){
      await new Promise((resolve, rejects) => {  this.bancoService
        .getCuentasBancarias(
          this.usuarioService.usuario.empresa.idEmpresa,
          false
        )
        .subscribe(
        (data: any) => {
          if (data) {

           
            this.banks = data;
           
            this.banks.forEach((x) => {
              if (!x.nombreBanco) {
                x.nombreBanco = x.codigoCurrencyB;
              }
            });
            resolve('');
          }
        },
        (error) => {
          rejects('');
        }
      )})
    //};

    // if (this.currencys.length === 0){
      await new Promise((resolve, rejects) => {  this.bancoService.getCurrencyBank().subscribe(
        (datos: any) => {
         
          this.currencys = datos.filter((x:any)=>x.nombreCurrencyB!=='Cash');
          resolve('');
        
        },
        (err) => {
          rejects('');
        }
      )})
    // };

  }


  constructor(private http: HttpClient, private globales: GlobalesService) {

  }

  async getOfTableFlags() {
    let datos=[];
  
    await this.xlsToJson('./assets/datosExcel/flags.xlsx')
      .then((data: any) => {
        // resolve(data);
  
        for (const [i, element] of data.entries()) {
  
          let a ={
            codePais:element[0],
            nombrePais:element[1]
          }
  
  
          datos.push(a);
  
  
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  
      return datos;
  
  }
  

  async getOfTableDockageVopak(nombreArchivo: string, tipo = '') {
    // let a = {
    //   valor:'',
    //   formula:'',
    //   currency:''
    // }
    let a;
    await this.xlsToJson('./assets/datosExcel/dk/' + nombreArchivo)
      .then((data: any) => {
        a = data.splice(1);
  
        if (tipo !== '') {
          for (const [i, element] of a.entries()) {
            if (element[0].toUpperCase() === tipo.toUpperCase()) {
              a.valor = element[2];
              a.formula = element[3];
              a.currency = element[4];
            }
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  
    return a;
  }

  async getOfTableDockage(nombreArchivo: string, horas: Number) {
    const a = {
      valor: '',
      formula: '',
      currency: '',
    };
  
    await this.xlsToJson('./assets/datosExcel/dk/' + nombreArchivo)
      .then((data: any) => {
        for (const [i, element] of data.entries()) {
          if (
            (Number(horas) >= Number(element[0]) && Number(horas) <= Number(element[1])) ||
            i === data.length - 1
          ) {
            a.valor = element[2];
            a.formula = element[3];
            a.currency = element[4];
            break;
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  
    return a;
  }

  async getOfTableArqueo(url: string, categoria: string, valor: any) {
    
    const a = {
      monto: '',
      code: '',
    };

    await this.xlsToJson(url)
      .then((data: any) => {
        // resolve(data);

        for (const [i, element] of data.entries()) {
          if (
            (valor >= Number(element[0]) && valor <= Number(element[1])) ||
            i === data.length - 1
          ) {
            switch (categoria.toLowerCase()) {
              case 'low':
                a.monto = element[2].toString().trim();
                break;
              case 'medium':
                a.monto = element[3].toString().trim();
                break;
              case 'high':
                a.monto = element[4].toString().trim();
                break;
            }
            a.code = element[5].toString().trim();
            break;
          }
        }
      })
      .catch((error) => {
         console.log(error);
      });

    return a;
  }

  async getOfTableHarbourDue(GRT:number) {
    const a = {
      valor: '',
      formula: '',
      currency: '',
    };

    await this.xlsToJson('./assets/datosExcel/harbourdues.xlsx')
      .then((data: any) => {
        for (const [i, element] of data.entries()) {
         
          if ((GRT >= Number(element[0]) && GRT <= Number(element[1])) || i === data.length - 1 ) {

            a.valor = element[2];
            a.formula = element[3];
            a.currency = element[4];
            break;
          }
        }
      })
      .catch((error) => {
        // console.log(error);
      });

    return a;
  }
  

  xlsToJson(url: string) {
    return new Promise((resolve, reject) => {
      try {
        let info;

        const req = new XMLHttpRequest();
        req.open('GET', url, true);
        req.responseType = 'arraybuffer';

        // const reader: FileReader = new FileReader();
        req.onload = (e: any) => {
          const data = new Uint8Array(req.response);
          const workbook = XLSXRead(data, { type: 'array' });

          const wsname: string = workbook.SheetNames[0];
          const ws: XLSXWorkSheet = workbook.Sheets[wsname];

          info = XLSXUtils.sheet_to_json(ws, { header: 1 });

          resolve(info);

          // return info;
        };

        req.send();
      } catch (error) {
        // console.log(error);
        // return error;
        reject(error);
      }
    });
  }

  getTabulatorItems(idEmpresa,idPuerto){
    return this.http.get(`${this.globales.dominioApi}/puerto/getTabulatorItems`, {
      params: {
        idEmpresa,
        idPuerto
      },
    });
  }

  getInfoVessel(imo:string){
    return this.http.get(`${this.urlMT}/vesselmasterdata/${this.apiKeyMT}`, {
      params: {
        v:5,
        imo,
        interval:'minutes',
        protocol:'jsono',
        page:1,
        msgtype:'simple'
      },
    });
  }

  getNotifications(idUsuario:string){
    return this.http.get(`${this.globales.dominioApi}/pda/getNotifications`, {
      params: {
        idUsuario
      },
    });
  }
  
  deleteNotification(idNotiUsuario:string){
    return this.http.get(`${this.globales.dominioApi}/pda/deleteNotification`, {
      params: {
        idNotiUsuario
      },
    });
  }

  deleteAllNotification(idUsuario:string){
    return this.http.get(`${this.globales.dominioApi}/pda/deleteAllNotification`, {
      params: {
        idUsuario
      },
    });
  }

  getImoRequest(imo:string){
    return this.http.get(`${this.globales.dominioApi}/pda/getImoRequest`, {
      params: {
        imo
      },
    });
  }

  saveImoRequest(datos){
    return this.http.post(`${this.globales.dominioApi}/pda/saveImoRequest`, datos);
  }

  saveCallInvitation(datos){
    return this.http.post(`${this.globales.dominioApi}/pda/saveCallInvitation`, datos);
  }

  saveTimesCargoReports(datos){
    return this.http.post(`${this.globales.dominioApi}/pda/saveTimesCargoReports`, datos);
  }

  cleanInvoice(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/cleanInvoice`,datos);
  }
  
  pdatoappointment(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/pdatoappointment`,datos);
  }

  checkVoyage(idEmpresa: string, agentVoyage: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/checkVoyage`, {
      params: {
        idEmpresa,
        agentVoyage
      },
    });
  }

  cancelAppointment(datos) {
    return this.http.post(`${this.globales.dominioApi}/pda/cancelAppointment`, datos);
  }
  cancelInvitation(datos) {
    return this.http.post(`${this.globales.dominioApi}/pda/cancelInvitation`, datos);
  }

  saveLogOp(datos: object) {
    return this.http.post(
      `${this.globales.dominioApi}/pda/saveLogOp`,
      datos
    );
  }

  getLogOp(datos) {
    return this.http.post(`${this.globales.dominioApi}/pda/getLogOp`, datos);
  }

  saveStoragePDAViewer(datos) {
    localStorage.setItem('pdaViewer', JSON.stringify(datos))
  }

  isAuth() {
    let a = JSON.parse(sessionStorage.getItem('pdaViewer'));

    if (a) {
      this.pdaViewer = a;


    }
    if (this.pdaViewer !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  getMensajesComentsRechazoAppo(idAppointment, quienConsulta) {
    return this.http.get(`${this.globales.dominioApi}/pda/getMensajesComentsRechazoAppo`, {
      params: {
        idAppointment,
        quienConsulta
      },
    });
  }

  getSuplementary(idSuplementary) {
    return this.http.get(`${this.globales.dominioApi}/pda/getSuplementary`, {
      params: {
        idSuplementary
      },
    });
  }

  getCuentasSuplementarys(idSuplementary) {
    return this.http.get(`${this.globales.dominioApi}/pda/getCuentasSuplementarys`, {
      params: {
        idSuplementary
      },
    });
  }

  getPlantillaPortEvent(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/getPlantillaPortEvent`, {
      params: {
        idAppointment
      },
    });
  }

  getReportsPortEvent(idAppointment='',tipo=0) {
    return this.http.get(`${this.globales.dominioApi}/pda/getReportsPortEvent`, {
      params: {
        idAppointment,
        tipo
      },
    });
  }

  addReportsPortEvent(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/addReportsPortEvent`, obj);
  }

  deleteReportsPortEvent(idReport='') {
    return this.http.get(`${this.globales.dominioApi}/pda/deleteReportsPortEvent`, {
      params: {
        idReport
      },
    });
  }

  getPortEvent(idAppointment='',idReport='') {
    return this.http.get(`${this.globales.dominioApi}/pda/getPortEvent`, {
      params: {
        idAppointment,
        idReport
      },
    });
  }

  addPortEvent(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/addPortEvent`, obj);
  }

  deletePortEvent(idPortEvent='',idReport='') {
    return this.http.get(`${this.globales.dominioApi}/pda/deletePortEvent`, {
      params: {
        idPortEvent,
        idReport
      },
    });
  }

  getSuplementarys(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/getSuplementarys`, {
      params: {
        idAppointment
      },
    });
  }

  getNotyMensRechazoAppo(quienConsulta, idAppointment, idEmpresa) {
    return this.http.get(`${this.globales.dominioApi}/pda/getNotyMensRechazoAppo`, {
      params: {
        idAppointment,
        idEmpresa
      },
    });
  }

  addMensajeRechazoAppo(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/addMensajeRechazoAppo`, obj);
  }


  saveCommentFDA(datos){
    return this.http.post(`${this.globales.dominioApi}/pda/saveCommentFDA`, datos);
  }

  saveCuentaFDA(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveCuentaFDA`, obj);
  }

  addSuplementary(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/addSuplementary`, obj);
  }

  addCuentaSuplementary(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/addCuentaSuplementary`, obj);
  }

  addRegisterAdvance(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/addRegisterAdvance`, obj);
  }

  revCuentas(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/revCuentas`, obj);
  }

  revCuentasSuplementary(obj) {
    return this.http.post(`${this.globales.dominioApi}/pda/revCuentasSuplementary`, obj);
  }

  changeStatusAppointment(obj) {
    return this.http.post(
      `${this.globales.dominioApi}/pda/changeStatusAppointment`,
      obj
    );
  }

  fileFda(idAppointment){
    return this.http.get(`${this.globales.dominioApi}/pda/fileFda`, {
      params: {
        idAppointment
      },
    });
  }
  fileVoyage(idAppointment,toStatus=2){
    return this.http.get(`${this.globales.dominioApi}/pda/fileVoyage`, {
      params: {
        idAppointment,
        toStatus
      },
    });
  }


  addPago(datos: object) {
    return this.http.post(
      `${this.globales.dominioApi}/pda/addPago`,
      datos
    );
  }

  getPagos(idAppointment) {
    return this.http.get(
      `${this.globales.dominioApi}/pda/getPagos`,{
        params: {
          idAppointment
        },
      });
  }

  deletePago(idPago,idUsuario) {
    return this.http.get(
      `${this.globales.dominioApi}/pda/deletePago`,{
        params: {
          idPago,
          idUsuario
        }
      });
  }

  deleteCuentaSuplementary(idCuenta,idUsuario) {
    return this.http.get(
      `${this.globales.dominioApi}/pda/deleteCuentaSuplementary`,{
        params: {
          idCuenta,
          idUsuario
        }
      });
  }

  deleteSuplementary(idSuplementary,idUsuario) {
    return this.http.get(
      `${this.globales.dominioApi}/pda/deleteSuplementary`,{
        params: {
          idSuplementary,
          idUsuario
        }
      });
  }

  saveAppointment(datos: object) {
    return this.http.post(
      `${this.globales.dominioApi}/pda/saveAppointment`,
      datos
    );
  }

  savePDA(datos: object) {
    return this.http.post(
      `${this.globales.dominioApi}/pda/savePDA`,
      datos
    );
  }

  saveValorFda(datos) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveValorFda`,datos);
  }

  saveCuentaValorSuplementary(datos) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveCuentaValorSuplementary`,datos);
  }

  saveFileFda(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveFileFda`, datos);
  }

  saveFileCuentaSuplementary(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveFileCuentaSuplementary`, datos);
  }


  getVoyage(idPDA: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/getVoyage`, {
      params: {
        idPDA
      },
    });
  }

  getPDA(idPDA: string, quienConsulta, changeView = '0') {
    return this.http.get(`${this.globales.dominioApi}/pda/getPDA`, {
      params: {
        idPDA,
        changeView,
        quienConsulta
      },
    });
  }

  saveStatusVesselAppointment(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveStatusVesselAppointment`, datos);
  }

  savecargoStorages(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/savecargoStorages`, datos);
  }

  addNotaVoyage(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/addNoteVoyage`, datos);
  }

  
  getFda(idAppointment:string,returnCuentas:boolean= true, returnSuplementarys: boolean=false ) {

    return this.http.get(`${this.globales.dominioApi}/pda/getFda`, {
      params:{
        idAppointment,
        returnCuentas,
        returnSuplementarys
      }
    });
  }
  portal_get_fda(idAppointment:string,idCustomer=null, returnCuentas:boolean= true, returnSuplementarys: boolean=false ) {

    return this.http.get(`${this.globales.dominioApi}/pda/portal_get_fda`, {
      params:{
        idAppointment,
        idCustomer,
        returnCuentas,
        returnSuplementarys
      }
    });
  }

  getCuentasFda(idAppointment:string,idPDA:string) {

    return this.http.get(`${this.globales.dominioApi}/pda/getCuentasFda`, {
      params:{
        idAppointment,
        idPDA  
      }
    });
  }

  getInvoices(datos: object) {

    return this.http.post(`${this.globales.dominioApi}/pda/getInvoices`, datos);
  }

  getOpBook(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/getOpBook`, datos);
  }

  getFdas(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/getFdas`, datos);
  }

  getVoyages(datos: object) {

    return this.http.post(`${this.globales.dominioApi}/pda/getVoyages`, datos);
  }
  getBoard(datos: object) {

    return this.http.post(`${this.globales.dominioApi}/pda/getBoard`, datos);
  }

  getPDAs(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/getPDAs`, datos);
  }


  getPortHub(idAppointment: string){
    return this.http.get(`${this.globales.dominioApi}/pda/getPortHub`,  {
      params: {
        idAppointment
      },
    });
  }

  getReportInvoices(datos: object){
    return this.http.post(`${this.globales.dominioApi}/pda/getReportInvoices`, datos );
  }
  getReportFda(datos: object){
    return this.http.post(`${this.globales.dominioApi}/pda/getReportFda`, datos );
  }

  getBillingsParty(idEmpesa: string){
    return this.http.get(`${this.globales.dominioApi}/pda/getBillingsParty`,  {
      params: {
        idEmpesa
      },
    });
  }

  deletePDA(idPDA: string, idUsuario: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/deletePDA`, {
      params: {
        idPDA,
        idUsuario
      },
    });
  }

  deleteCargoReport(idCargoReport: string, idUsuario: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/deleteCargoReport`, {
      params: {
        idCargoReport,
        idUsuario
      },
    });
  }

  deleteAdicionalExpense(idAdicionalExpense: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/deleteAdicionalExpense`, {
      params: {
        idAdicionalExpense
      },
    });
  }

  deleteRegisterAdvance(idRegisterAdvance: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/deleteRegisterAdvance`, {
      params: {
        idRegisterAdvance
      },
    });
  }

  getCargoReport(idAppointment: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/getCargoReport`, {
      params: {
        idAppointment
      },
    });
  }

  getCargoReportPortal(idAppointment: string) {
    return this.http.get(`${this.globales.dominioApi}/pda/getCargoReportPortal`, {
      params: {
        idAppointment
      },
    });
  }

  saveCargoReport(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveCargoReport`, datos);
  }
  
  sendPDA(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/sendFilePDA`, datos);
  }

  sendFDA(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/sendFDA`, datos);
  }
  sendDebtcollection(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/sendDebtCollection`, datos);
  }

  uptVoyageForSendFDA(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/uptVoyageForSendFDA`, datos);
  }

  addComentPDA(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/addComentCuenta`, datos);
  }

  addComentCuentaSuplementary(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/addComentCuentaSuplementary`, datos);
  }

  getAgencysType() {
    return this.http.get(`${this.globales.dominioApi}/pda/getAgencysType`);
  }

  getEventsSof() {
    return this.http.get(`${this.globales.dominioApi}/pda/getEventsSof`);
  }

  getEventTypeSof() {
    return this.http.get(`${this.globales.dominioApi}/pda/getEventTypeSof`);
  }

  saveSof(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveSof`, datos);
  }

  saveRemarksSof(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveRemarksSof`, datos);
  }

  saveSignatureSof(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/saveSignatureSof`, datos);
  }

  getSof(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/getSof`, {
      params: {
        idAppointment
      }
    });
  }


  getAllAccountsPDA(idPda, quienconsulta = "") {
    return this.http.get(`${this.globales.dominioApi}/pda/getAllAccountsPDA`, {
      params: {
        idPda,
        quienconsulta
      }
    });
  }

  getComentsPDA(idCuenta, quienConsulta) {
    return this.http.get(`${this.globales.dominioApi}/pda/getcommentsPDA`, {
      params: {
        idCuenta,
        quienConsulta
      }
    });
  }

  getComentsAdicionalExpense(idCuenta, quienConsulta) {
    return this.http.get(`${this.globales.dominioApi}/pda/getcommentsAdicionalExpense`, {
      params: {
        idCuenta,
        quienConsulta
      }
    });
  }

  getComentsPDAEmp(idEmpresa, quienConsulta) {
    return this.http.get(`${this.globales.dominioApi}/pda/getComentsPDAEmp`, {
      params: {
        idEmpresa,
        quienConsulta
      }
    });
  }

  getAppointmentEmpNoty(idEmpresa) {
    return this.http.get(`${this.globales.dominioApi}/pda/getAppointmentEmpNoty`, {
      params: {
        idEmpresa
      }
    });
  }

  notifyNewComents(datos: object) {
    return this.http.post(`${this.globales.dominioApi}/pda/notifyNewComents`, datos);
  }

  notyViewComentPDA(idComentario) {
    return this.http.get(`${this.globales.dominioApi}/pda/notyViewComentPDA`, {
      params: {
        idComentario
      }
    });
  }

  notyViewNewAppointment(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/notyViewNewAppointment`, {
      params: {
        idAppointment
      }
    });
  }

  changeAllViewNoti(idEmpresa) {
    return this.http.get(`${this.globales.dominioApi}/pda/changeAllViewNoti`, {
      params: {
        idEmpresa
      }
    });
  }

  getCountAppo(idEmpresa:string) {
    return this.http.get(`${this.globales.dominioApi}/pda/getCountAppo`, {
      params: {
        idEmpresa
      }
    });
  }

  getAppointment(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/getAppointment`, {
      params: {
        idAppointment
      }
    });
  }

  getDashConsulta(idEmpresa,dateSearchStart,dateSearchEnd) {
    return this.http.get(`${this.globales.dominioApi}/pda/getDashConsulta`, {
      params: {
        idEmpresa,
        dateSearchStart,
        dateSearchEnd
      }
    });
  }

  getDashPortalConsulta(idCustomer,dateSearchStart,dateSearchEnd) {
    return this.http.get(`${this.globales.dominioApi}/pda/getDashPortalConsulta`, {
      params: {
        idCustomer,
        dateSearchStart,
        dateSearchEnd
      }
    });
  }

  getVoyagesPortalConsulta(idCustomer,search) {
    return this.http.get(`${this.globales.dominioApi}/pda/getVoyagesPortalConsulta`, {
      params: {
        idCustomer,
        search
      }
    });
  }

  getAppointmentsEmp(idEmpresa) {
    return this.http.get(`${this.globales.dominioApi}/pda/getAppointmentsEmp`, {
      params: {
        idEmpresa
      }
    });
  }

  getCallInvitations(idEmpresa) {
    return this.http.get(`${this.globales.dominioApi}/pda/getCallInvitations`, {
      params: {
        idEmpresa
      }
    });
  }

  getAdicionalExpenses(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/getAdicionalExpenses`, {
      params: {
        idAppointment
      }
    });
  }

  getRegisterAdvances(idAppointment) {
    return this.http.get(`${this.globales.dominioApi}/pda/getRegisterAdvances`, {
      params: {
        idAppointment
      }
    });
  }
  

  getHandlings() {
    return this.http.get(`${this.globales.dominioApi}/pda/getHandlings`);
  }

}


